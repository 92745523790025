// ParsleyConfig definition if not already set
window.ParsleyConfig = window.ParsleyConfig || {};
window.ParsleyConfig.i18n = window.ParsleyConfig.i18n || {};

// Define then the messages
window.ParsleyConfig.i18n.en = $.extend(window.ParsleyConfig.i18n.en || {}, {
  defaultMessage: "A megadott érték érvénytelen",
  type: {
    email:        "Hibás e-mail cím formátum",
    url:          "A beírt értéknek érvényes url-nek kell lennie.",
    number:       "A beírt értéknek számnak kell lennie.",
    integer:      "A beírt értéknek egész számnak kell lennie.",
    digits:       "A beírt értéknek számnak kell lennie.",
    alphanum:     "A beírt értéknek alfanumerikus karakternek kell lennie."
  },
  notblank:       "A mező kitöltése kötelező.",
  required:       "A mező kitöltése kötelező.",
  pattern:        "A megadott érték érvénytelen.",
  min:            "A megadott érték legyen nagyobb vagy egyenlő, mint %s.",
  max:            "A megadott érték legyen kisebb vagy egyenlő, mint  %s.",
  range:          "A megadott értéknek %s és %s közöttinek kell lennie.",
  minlength:      "Ez az érték túl rövid. Legalább %s karakter kell, hogy legyen, vagy több.",
  maxlength:      "This value is too long. It should have %s characters or less.",
  length:         "This value length is invalid. It should be between %s and %s characters long.",
  mincheck:       "You must select at least %s choices.",
  maxcheck:       "You must select %s choices or less.",
  check:          "You must select between %s and %s choices.",
  equalto:        "A két érték nem egyezik meg!"
});

// If file is loaded after Parsley main file, auto-load locale
if ('undefined' !== typeof window.ParsleyValidator)
  window.ParsleyValidator.addCatalog('en', window.ParsleyConfig.i18n.en, true);
