$(document).ready(function () {

    google.maps.event.addDomListener(window, 'load', init);

    function init() {

        var mapOptions = {
            zoom: 11,

            controls: {
                panControl: false,
                mapTypeControl: false,
                streetViewControl: false,
                rotateControl: false
            },
            scrollwheel: false,
            markers: [],
            icon: {
                image: "assets/frontend/images/marker.png",
                shadow: "http://www.google.com/mapfiles/shadow50.png",
                iconsize: [72, 104],
                shadowsize: [37, 34],
                iconanchor: [9, 34],
                shadowanchor: [9, 34]
            },

            center: new google.maps.LatLng(47.402379, 16.856792),

            styles: [{"featureType":"all","elementType":"all","stylers":[{"saturation":-80}]},{"featureType":"administrative.country","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"hue":"#ff0000"}]},{"featureType":"administrative.locality","elementType":"geometry.stroke","stylers":[{"visibility":"on"}]},{"featureType":"administrative.locality","elementType":"labels.text.fill","stylers":[{"hue":"#25ff00"}]},{"featureType":"landscape","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"hue":"#00ffee"},{"saturation":50}]}]

        };

        var mapElement = document.getElementById('map');

        var map = new google.maps.Map(mapElement, mapOptions);

        var locations = [
        ['Mihályfi Bt.', '9632 Sajtoskál, Rákóczi u 27.', 'undefined', 'undefined', 'undefined', 47.402379, 16.856792, 'assets/frontend/images/marker.png']
                ];
                for (i = 0; i < locations.length; i++) {
        			if (locations[i][1] =='undefined'){ description ='';} else { description = locations[i][1];}
        			if (locations[i][2] =='undefined'){ telephone ='';} else { telephone = locations[i][2];}
        			if (locations[i][3] =='undefined'){ email ='';} else { email = locations[i][3];}
                   if (locations[i][4] =='undefined'){ web ='';} else { web = locations[i][4];}
                   if (locations[i][7] =='undefined'){ markericon ='';} else { markericon = locations[i][7];}
                    marker = new google.maps.Marker({
                        icon: markericon,
                        position: new google.maps.LatLng(locations[i][5], locations[i][6]),
                        map: map,
                        title: locations[i][0],
                        desc: description,
                        tel: telephone,
                        email: email,
                        web: web
                    });
        link = '';            bindInfoWindow(marker, map, locations[i][0], description, telephone, email, web, link);
             }
         function bindInfoWindow(marker, map, title, desc, telephone, email, web, link) {
              var infoWindowVisible = (function () {
                      var currentlyVisible = false;
                      return function (visible) {
                          if (visible !== undefined) {
                              currentlyVisible = visible;
                          }
                          return currentlyVisible;
                       };
                   }());
                   iw = new google.maps.InfoWindow();
                   google.maps.event.addListener(marker, 'click', function() {
                       if (infoWindowVisible()) {
                           iw.close();
                           infoWindowVisible(false);
                       } else {
                           var html= "<div style='color:#000;background-color:#fff;padding:5px;width:150px;'><h4>"+title+"</h4><p>"+desc+"<p></div>";
                           iw = new google.maps.InfoWindow({content:html});
                           iw.open(map,marker);
                           infoWindowVisible(true);
                       }
                });
                google.maps.event.addListener(iw, 'closeclick', function () {
                    infoWindowVisible(false);
                });
         }
    }


});


